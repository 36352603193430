<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="预订单状态">
              <DictSelect field="advanceOrder" :value.sync="searchData.orderType" style="width: 100%" placeholder="请选择订单状态"></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="员工">
              <a-select placeholder="请选择员工" v-model="searchData.managerId" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in managerData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="客户名称">
              <a-input v-model="searchData.name" placeholder="客户名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="联系方式">
              <a-input v-model="searchData.phone" placeholder="联系方式" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="所在地区">
              <PCD
                ref="pcd"
                placeholder="请选择区域"
                :province.sync="searchData.province"
                :city.sync="searchData.city"
                :district.sync="searchData.area"
                :provinceId.sync="searchData.provinceId"
                :cityId.sync="searchData.cityId"
                :districtId.sync="searchData.areaId"
              >
              </PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="物业地址">
              <a-input v-model="searchData.address" placeholder="小区/栋/单元/室" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="申请日期">
              <DateRange :startTime.sync="searchData.createTimeStart" :endTime.sync="searchData.createTimeEnd"></DateRange>
            </a-form-model-item>
          </a-col>
          <!--<a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="成交日期">
              <DateRange :startTime.sync="searchData.dealTimeStart" :endTime.sync="searchData.dealTimeEnd"></DateRange>
            </a-form-model-item>
          </a-col>-->
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
<!--        <a-button v-if="addEnable" type="primary" @click="toHandler('add')">新增</a-button>-->
<!--        <a-button v-if="editEnable && selectedRows.length > 0" type="primary" @click="toHandler('edit')">修改</a-button>-->
<!--        <a-button v-if="removeEnable && selectedRows.length > 0" type="danger" @click="toHandler('del')">批量删除</a-button>-->
      </div>
      <!-- 数据表格 -->
      <a-table :scroll="{ x: 1800 }" :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
               :columns="columns" :data-source="tableData" bordered :pagination="page"
               @change="changeTable" :customRow="changeTableRow">
                  <span slot="chengJiaoProNum" slot-scope="text, row">
            <span v-if="text <= 0">未选择商品</span>
            <a-button v-else type="default" size="small" @click="checkGoods(row.id)" icon="eye">查看商品</a-button>
        </span>
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
        <span slot="orderType" slot-scope="text, row">
         <a-tag v-if="row.orderType == 1" color="#f50">
            零售
          </a-tag>
          <a-tag v-if="row.orderType == 2" color="#108ee9">
            项目
          </a-tag>
        </span>
        <span slot="propertyType" slot-scope="text, row">
          <a-tag  v-if="row.propertyType == 0" color="green">
            商品房
          </a-tag>
          <a-tag v-if="row.propertyType == 1" color="orange">
           自建房
          </a-tag>
        </span>
        <span slot="orderStatus" slot-scope="text">
              <span v-if="text === 0">未申请</span>
              <span v-if="text === 1">已申请</span>
              <span v-if="text === 2">已成交</span>
              <span v-if="text === 3">失效</span>
              <span v-if="text === 4">丢单</span>
              <span v-if="text === 5">无效</span>
              <span v-if="text === 6">过期</span>
        </span>
        <span slot="registrationStatus" slot-scope="text">
              <span v-if="text === 0">未申请</span>
              <span v-if="text === 1">首次申请</span>
              <span v-if="text === 2">二次申请</span>
              <span v-if="text === 3">申请成功</span>
        </span>
        <span slot="verifyStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#87d068">待处理</a-tag>
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#ff7875">审核拒绝</a-tag>
          <a-tag v-if="text == 99" color="#e6a23c">审核中</a-tag>
      </span>
        <span slot="sourceType" slot-scope="text">
          <a-tag v-if="text == 0">PC端</a-tag>
          <a-tag v-if="text == 1">小程序</a-tag>
        </span>
      <span slot="action" slot-scope="text, record">
        <a @click="checkLog(record)">查看日志</a>
      </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerCustomerOrder-edit-modal ref="DealerCustomerOrderEditModal"
                                      @reload="getData"></DealerCustomerOrder-edit-modal>
                                      <CheckGoods ref="CheckGoods"></CheckGoods>
    </div>
    <a-modal title="查看日志" :maskClosable="false" v-model="visibleLog" width="50%" :footer="null">
      <a-table
        :rowKey="(record) => record.id"
        :columns="columnsCheck"
        :data-source="tableDataCheck"
        bordered
        :pagination="false"
      >
      </a-table>
    </a-modal>
  </a-card>
</template>

<script>
import {columns} from './components/colums.js'
import CheckGoods from './components/CheckGoods.vue'
import DealerCustomerOrderEditModal from './components/DealerCustomerOrderEditModal.vue'
import {
  delDealerCustomerOrder,
  listDealerCustomerOrder,
  selectByIdDealerCustomerOrder
} from './api/DealerCustomerOrderApi'
import {checkPermission} from '@/utils/permissions'
import {batchQuXiaoDealerCustomerInfo} from "@/views/dealer_customer_info/api/DealerCustomerInfoApi";

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default {
    name: "InitialApplication",
    components: {
      DealerCustomerOrderEditModal,
      CheckGoods
    },
    data() {
      return {
        columns: columns,
        tableData: [],
        searchData: {}, // 搜索条件
        scroll: {y: 600},
        tableLoading: false, //表格loading
        visibleLog: false,
        tableDataCheck:[],
        columnsCheck: [
          {
            title: '客户',
            dataIndex: 'cusName',
            key: 'cusName',
            width:100,
            align: 'center',
            ellipsis: true,
          },
          {
            title: '操作人',
            dataIndex: 'createUser',
            key: 'createUser',
            width:100,
            align: 'center',
            ellipsis: true,
          },
          {
            title: '操作时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width:150,
            align: 'center',
            ellipsis: true,
          },
          {
            title: '操作日志',
            dataIndex: 'log',
            key: 'log',
            width:150,
            align: 'center',
            ellipsis: true,
          },
        ],
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showTotal: () => `共${this.page.total}条`,
        },
        selectedRowKeys: [],
        selectedRows: [],
        managerData: [],
        addEnable: checkPermission('dealer:customer_order:add'),
        editEnable: checkPermission('dealer:customer_order:edit'),
        removeEnable: checkPermission('dealer:customer_order:remove'),
      }
    },
    computed: {
      rowSelection() {
        const _this = this
        return {
          fixed: true,
          type: 'radio', //"radio"
          selectedRowKeys: this.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            _this.selectedRowKeys = selectedRowKeys
            _this.selectedRows = selectedRows
          }
        }
      }
    },
    methods: {
      // 查看日志
      checkLog(row) {
        this.visibleLog = true
        this.axios.get('/api/dealer/quarters/dealerCustomerOrderLog/listByCusId/' + row.cusId).then((res) => {
          if(res.body){
            this.tableDataCheck=res.body
          }
        })
      },

      checkGoods(id){
      this.$refs.CheckGoods.isShow(id)
    },
      /**
       * 获取表格数据
       */
      getData() {
        this.tableLoading = true
        // this.searchData.orderType = 1
        this.searchData.registrationStatus = 1
        listDealerCustomerOrder({
          pageNumber: this.page.current,
          pageSize: this.page.pageSize,
          ...this.searchData
        }).then(res => {
          if (res.code === 200) {
            const {total, records} = res.body
            this.page.total = total
            this.tableData = records
          }
        }).finally(() => this.tableLoading = false)
        this.axios.get(`/api/base/system/dealer/manager/managerListForDealer`).then((res) => {
          if (res.code == 200) {
            this.managerData = res.body
          }
        })
      },
      /**
       * 筛选查询
       */
      getDataFilter() {
        // this.page = Object.assign({}, this.page)
        this.page.current = 1
        this.page.pageSize = 10
        this.getData()
      },
      /**
       * 重置
       */
      reset() {
        this.searchData = {}
        // this.page = Object.assign({}, this.page)
        this.page.current = 1
        this.page.pageSize = 10
        this.getData()
      },
      /**
       * 更改分页
       */
      changeTable(pagination) {
        this.page = pagination
        this.getData()
      },
      /**
       * 点击行选中
       */
      changeTableRow(record) {
        return {
          on: {
            click: e => {
              console.log(e)
              if (this.selectedRowKeys[0] === record.id) {
                this.selectedRowKeys = []
                this.selectedRows = []
              } else {
                this.selectedRowKeys = [record.id]
                this.selectedRows = [record]
              }
            }
          }
        }
      },
      toHandler(name) {
        const _this = this
        if (name === 'add') {
          _this.$refs.DealerCustomerOrderEditModal.setRowData({}, 'add')
          return
        }
        if (_this.selectedRows.length === 0) {
          _this.$notification.warning({
            message: '请选择一条记录'
          })
          return
        }
        switch (name) {
          case 'edit':
            _this.$refs.DealerCustomerOrderEditModal.setRowData(_this.selectedRows[0], 'edit')
            break
          case 'del':
            let arr = []
            for (var i = 0; i < this.selectedRows.length; i++) {
              if (arr.indexOf(this.selectedRows[i].orderStatus) === -1) {
                arr.push(this.selectedRows[i].orderStatus)
              }
            }

            if (arr.indexOf(2) >= 0 || arr.indexOf(3) >= 0 ||arr.indexOf(4) >= 0 ) {
              return this.$message.warning('选中的数据中包含不可删除的记录')
            }
            const cusIds = this.selectedRows.map((item) => item.cusId);

            _this.$confirm({
              title: '删除警告',
              content: '确认要删除选中记录吗?',
              okText: '确认',
              cancelText: '取消',
              async onOk() {
                const res = await batchQuXiaoDealerCustomerInfo(cusIds)
                if (res.code === 200) {
                  _this.$notification.success({ message: res.message })
                  _this.getData()
                } else {
                  _this.$notification.error({ message: res.message })
                }
              },
              onCancel() {},
            })
            break
        }
      },
    },
    created() {
      this.getData()
    }
}

</script>

<style scoped>

</style>